import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import cx from "classnames";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";

import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import {
  useCommonStyles,
  useStyles as useStepStyles,
} from "../../../../utils/loan-application.styles";
import BaseMoneyInput from "../../components/Inputs/BaseMoneyInput";
import SingleInput from "../../components/Inputs/SingleInput";
import {
  debtOptions,
  moneyTypeOptions,
} from "../../../../commons/loan-application-constants";
import Button from "../../components/Button";
import DebtSummaryCard from "./DebtSummaryCard";
import { setFieldTouchedRecusively } from "../../../../utils/loan-application.utils";

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    width: "100%",
    display: "grid",
    gridGap: "24px 24px",
    gridTemplateColumns: "1fr 1fr",
    gridAutoRows: "1fr 1fr",
    marginBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
  },
  container: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gridRowGap: "48px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  owningInputContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "48px",
  },
}));

const CommonDebt = ({
  formik,
  index: debtTypeIndex,
  handleNextStep,
  handlePrevStep,
  handleSaveSection,
  isItemFinished,
  setIsItemFinished,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const stepClasses = useStepStyles();
  const isMobile = useMediaQuery("(max-width:599px)");
  const [isFinished, setIsFinished] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  // Set initial state based on isEditing or isPropertyFinished
  React.useEffect(() => {
    if (isItemFinished) {
      setIsFinished(true);
    }
  }, []);

  // Cleanup invalid items when showing list view
  React.useEffect(() => {
    if (isFinished) {
      const validItems = formik.values.debts[debtTypeIndex].items.filter(
        (_, itemIndex) => {
          const itemErrors =
            formik.errors?.debts?.[debtTypeIndex]?.items?.[itemIndex];
          return !itemErrors;
        }
      );

      formik.setFieldValue(`debts[${debtTypeIndex}].items`, validItems);
      setIsItemFinished(true);
    }
  }, [isFinished]);

  const currentDebtValuesObject = formik.values.debts
    ?.at(debtTypeIndex)
    ?.items?.at(currentItemIndex);
  const currentDebtTouchedObject = formik.touched.debts
    ?.at(debtTypeIndex)
    ?.items?.at(currentItemIndex);
  const currentDebtErrorsObject = formik.errors.debts
    ?.at(debtTypeIndex)
    ?.items?.at(currentItemIndex);
  const currentDebtFieldPath = `debts[${debtTypeIndex}].items[${currentItemIndex}]`;

  const validateFields = async () => {
    const data = await formik.validateForm();
    if (!data?.debts?.[debtTypeIndex]?.items?.[currentItemIndex]) {
      return true;
    }
    formik.setFieldTouched(`${currentDebtFieldPath}.currentOwing`);
    formik.setFieldTouched(`${currentDebtFieldPath}.originalLoanAmount`);
    formik.setFieldTouched(`${currentDebtFieldPath}.loanRepaymentAmount`);
    formik.setFieldTouched(`${currentDebtFieldPath}.detail`);
    formik.setFieldTouched(`${currentDebtFieldPath}.liabilityOwners`);

    return false;
  };

  const onNextStep = async () => {
    if (await validateFields()) {
      setIsFinished(true);
      handleNextStep();
    }
  };

  const onSaveSection = async () => {
    if (await validateFields()) {
      setIsFinished(true);
      handleSaveSection();
    }
  };

  const onAddItem = async () => {
    const newIndex = formik.values.debts[debtTypeIndex].items.length;
    const ownership = formik.values.debts[0].items[0]?.liabilityOwners || [];
    const newItem = formik.initialValues.debts[0].items[0];
    newItem.liabilityOwners = ownership;
    await formik.setFieldValue(
      `debts[${debtTypeIndex}].items[${newIndex}]`,
      newItem
    );
    await setFieldTouchedRecusively(
      formik,
      `debts[${debtTypeIndex}].items[${newIndex}]`,
      false,
      false
    );
    setIsFinished(false);
    setCurrentItemIndex(newIndex);
  };

  const onDeleteItem = async () => {
    await formik.setFieldValue(
      `debts[${debtTypeIndex}].items`,
      [...formik.values.debts[debtTypeIndex].items].filter(
        (_, i) => i !== currentItemIndex
      )
    );
    setIsFinished(true);
    setCurrentItemIndex(0);
  };

  const handleEditItem = (itemIndex) => {
    setCurrentItemIndex(itemIndex);
    setIsFinished(false);
  };

  const handleSaveItem = async () => {
    if (await validateFields()) {
      setIsFinished(true);
    }
  };

  if (isFinished) {
    return (
      <Box>
        <Box classes={{ root: classes.summaryContainer }}>
          {formik.values.debts[debtTypeIndex].items.map((item, itemIndex) => (
            <DebtSummaryCard
              // eslint-disable-next-line react/no-array-index-key
              key={itemIndex}
              debt={item}
              onEdit={() => handleEditItem(itemIndex)}
              debtType={formik.values.debts[debtTypeIndex].debtType}
              index={itemIndex}
            />
          ))}
        </Box>
        <Grid container xs={12} md={6} justifyContent="space-between">
          <Button endIcon={<Add />} onClick={onAddItem}>
            Add Additional{" "}
            {
              debtOptions.find(
                (option) =>
                  option.value === formik.values.debts[debtTypeIndex].debtType
              ).shortTerm
            }
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          md={6}
          justifyContent="space-between"
          classes={{ root: stepClasses.navigationButtonsContainer }}
        >
          {debtTypeIndex !== 0 && (
            <Button startIcon={<NavigateBefore />} onClick={handlePrevStep}>
              Previous
            </Button>
          )}
          {debtTypeIndex !== formik.values.debts.length - 1 ? (
            <Button endIcon={<NavigateNext />} onClick={onNextStep}>
              Next
            </Button>
          ) : (
            <Button endIcon={<NavigateNext />} onClick={onSaveSection}>
              Save and Next
            </Button>
          )}
        </Grid>
      </Box>
    );
  }

  return (
    <Box classes={{ root: classes.container }}>
      <Box display="flex" flexDirection="column" gridRowGap="48px">
        <Box classes={{ root: classes.owningInputContainer }}>
          <BaseMoneyInput
            label="How much is owing"
            value={currentDebtValuesObject?.currentOwing}
            onChange={(value) =>
              formik.setFieldValue(
                `${currentDebtFieldPath}.currentOwing`,
                value
              )
            }
            error={
              currentDebtTouchedObject?.currentOwing &&
              Boolean(currentDebtErrorsObject?.currentOwing)
            }
            helperText={
              currentDebtTouchedObject?.currentOwing &&
              currentDebtErrorsObject?.currentOwing
            }
          />
          <BaseMoneyInput
            label="Original loan amount"
            value={currentDebtValuesObject?.originalLoanAmount}
            onChange={(value) =>
              formik.setFieldValue(
                `${currentDebtFieldPath}.originalLoanAmount`,
                value
              )
            }
            error={
              currentDebtTouchedObject?.originalLoanAmount &&
              Boolean(currentDebtErrorsObject?.originalLoanAmount)
            }
            helperText={
              currentDebtTouchedObject?.originalLoanAmount &&
              currentDebtErrorsObject?.originalLoanAmount
            }
          />
        </Box>
        <BaseMoneyInput
          options={moneyTypeOptions}
          label="How much are your loan repayments"
          optionLabel="Repayment  Frequency"
          selectedOption={currentDebtValuesObject?.repaymentFrequency}
          setSelectedOption={(value) =>
            formik.setFieldValue(
              `${currentDebtFieldPath}.repaymentFrequency`,
              value
            )
          }
          value={currentDebtValuesObject?.loanRepaymentAmount}
          maxValue={5000}
          onChange={(value) =>
            formik.setFieldValue(
              `${currentDebtFieldPath}.loanRepaymentAmount`,
              value
            )
          }
          error={
            currentDebtTouchedObject?.loanRepaymentAmount &&
            Boolean(currentDebtErrorsObject?.loanRepaymentAmount)
          }
          helperText={
            currentDebtTouchedObject?.loanRepaymentAmount &&
            currentDebtErrorsObject?.loanRepaymentAmount
          }
        />
        <TextField
          name={`${currentDebtFieldPath}.detail`}
          onChange={formik.handleChange}
          value={currentDebtValuesObject?.detail}
          variant="outlined"
          label="Details"
          placeholder="Short comment"
          fullWidth
          InputLabelProps={{
            shrink: true,
            color: "primary",
          }}
          error={
            currentDebtTouchedObject?.detail &&
            Boolean(currentDebtErrorsObject?.detail)
          }
          helperText={
            currentDebtTouchedObject?.detail && currentDebtErrorsObject?.detail
          }
          FormHelperTextProps={{
            style: { position: "absolute", bottom: "-20px" },
          }}
        />
        <Box display="flex" gridColumnGap="16px" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gridRowGap="16px">
            <Typography
              classes={{
                root: cx(
                  commonClasses.fontSize14,
                  commonClasses.fontWeightBold
                ),
              }}
            >
              Who Owns the Liability
            </Typography>
            {currentDebtValuesObject?.liabilityOwners?.length ? (
              <Box display="flex" gridColumnGap="48px" flexWrap="wrap">
                {currentDebtValuesObject?.liabilityOwners.map(
                  (owner, ownerIndex) => (
                    <SingleInput
                      // eslint-disable-next-line react/no-array-index-key
                      key={`common-debt-owner-${debtTypeIndex}-${owner.name}`}
                      type="number"
                      label={owner.name}
                      endAdornment="%"
                      value={
                        currentDebtValuesObject?.liabilityOwners[ownerIndex]
                          .percentage
                      }
                      onChange={(value) =>
                        formik.setFieldValue(
                          `${currentDebtFieldPath}.liabilityOwners[${ownerIndex}].percentage`,
                          value
                        )
                      }
                    />
                  )
                )}
                {currentDebtErrorsObject?.liabilityOwners &&
                  currentDebtTouchedObject?.liabilityOwners && (
                    <Typography color="error">
                      {currentDebtErrorsObject?.liabilityOwners}
                    </Typography>
                  )}
              </Box>
            ) : (
              <Box>
                <Typography color="error">
                  Please enter applicant(s) information first
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box classes={{ root: classes.actionButtonsContainer }}>
        <Button endIcon={<Add />} onClick={handleSaveItem}>
          Save {`${isMobile ? "" : " This Debt"}`}
        </Button>
        {formik.values.debts[debtTypeIndex].items.length > 1 && (
          <Button
            endIcon={<Close />}
            onClick={onDeleteItem}
            customColor="danger"
          >
            Delete {`${isMobile ? "" : " This Debt"}`}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CommonDebt;
